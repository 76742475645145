import { css } from 'styled-components';

const LayoutCampusList = css`
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
`;

const LayoutIronhackInternational = css`
  padding-bottom: 4.8rem;
`;

export { LayoutCampusList, LayoutIronhackInternational };
