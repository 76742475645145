import React from 'react';
import withI18next from '../../i18n/TemplateI18n';
import { graphql } from 'gatsby';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import IronhackInternational from '../../components/IronhackInternational';
import CampusList from '../../components/CampusList';
import Footer from '../../components/Footer';
import { LayoutCampusList, LayoutIronhackInternational } from './styles';
import AllCampusProps from '../../interface/template';
import BlogArticlesSample from '../../components/BlogArticlesSample';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

const AllCampuses: React.SFC<any> = (props: AllCampusProps) => {
  const { t } = props;
  const { locale, pageName } = props.pageContext;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header showBackground={true} pageName={pageName}>
        <PageTitle
          title={t('all-campuses:allCampuses.mainHeadline.title')}
          subtitle={t('all-campuses:allCampuses.mainHeadline.subtitle')}
        />
      </Header>
      <IronhackInternational
        listQuestions={[
          {
            answer: t(
              'all-campuses:allCampuses.secondaryHeadline.globalSchoolDescription'
            ),
            question: t(
              'all-campuses:allCampuses.secondaryHeadline.globalSchool'
            ),
          },
          {
            answer: t(
              'all-campuses:allCampuses.secondaryHeadline.missionDescription'
            ),
            question: t('all-campuses:allCampuses.secondaryHeadline.mission'),
          },
        ]}
      />
      <CampusList
        locale={locale}
        extend={LayoutCampusList}
        cohorts={props.pageContext.cohorts}
      />
      <IronhackInternational
        extend={LayoutIronhackInternational}
        title=""
        listQuestions={[
          {
            answer: [
              t('all-campuses:allCampuses.becomeIronhacker.description.01'),
              t('all-campuses:allCampuses.becomeIronhacker.description.02'),
            ],
            question: t('all-campuses:allCampuses.becomeIronhacker.title'),
          },
        ]}
      />
      <BlogArticlesSample category="GLO" />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(campus-list)|(all-campuses)|(course-list)|(languages)|(seo)|(breadcrumbs)|(banner)|(blog)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(AllCampuses);
